import { HashRouter as Router } from "react-router-dom";
import {ThemeSwitcherProvider, useThemeSwitcher} from "react-css-theme-switcher";

import { FixedGlobalStyle, ThemedGlobalStyle } from "./theme";
import RouteChanger from "./components/RouteChanger/routeChanger";
import WalletModal from "./components/WalletModal";
import ApplicationUpdater from "./state/application/updater";
import ListsUpdater from "./state/lists/updater";
import MultiCallUpdater from "./state/multicall/updater";
import TransactionUpdater from "./state/transactions/updater";
import UserUpdater from "./state/user/updater";
import Routes from "./Routes";
import "./global.scss";
import Web3ReactManager from "./components/Web3ReactManager";
import TransactionHandler from "./components/TransactionHandler";
import {useIsDarkMode} from "./state/user/hooks";
import {SkeletonTheme} from "react-loading-skeleton";

function Updaters() {
	return (
		<>
			<ListsUpdater />
			<UserUpdater />
			<ApplicationUpdater />
			<TransactionUpdater />
			<MultiCallUpdater />
		</>
	);
}

const themes = {
	dark: `${process.env.PUBLIC_URL}/styles/dark.css`,
	light: `${process.env.PUBLIC_URL}/styles/light.css`,
};
function App() {
	const darkMode = useIsDarkMode()

	return (
		<Web3ReactManager>
			<FixedGlobalStyle />
			<Updaters />
			<TransactionHandler />

			<SkeletonTheme
				color={darkMode ? "#003262" : "rgb(212, 218, 242)"}
				highlightColor={darkMode ? "#0054A3" : "#F3F5FD"}
			>
				<Router>
					<ThemeSwitcherProvider themeMap={themes} defaultTheme={darkMode ? 'dark' : 'light'}>
						<ThemedGlobalStyle />
						<WalletModal />
						<RouteChanger />
						<Routes />
					</ThemeSwitcherProvider>
				</Router>
			</SkeletonTheme>
		</Web3ReactManager>
	);
}

export default App;
