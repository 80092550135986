export const MainnetAPYPools = {
	"DAI": "0x75CE0E501e2E6776FcAAa514f394a88a772A8970",
	"USDC": "0xe18b0365D5D09F394f84eE56ed29DD2d8D6Fba5f",
	"USDT": "0xeA9c5a2717D5Ab75afaAC340151e73a7e37d99A7"
}

export const KovanAPYPools = {
	"DAI": "0x40A7fC51fa572737D0DceE15852617AD7cb9c5b3",
	"USDC": "0xf42E8a60bc63993b04B17c87dF821d5ddD1C8D0e",
	"USDT": "0xef35Fe92518156f17e7d879CFD27006f1a6765E3"
}