import { createReducer } from "@reduxjs/toolkit";
import {setLoading, setPoolsTokenEtherPrice, setTotalEtherValueLocked} from "./actions";

const initialState = {
	loading: false,
	totalEtherValueLocked: {},
	poolsTokenEtherPrice: {},
}

export default createReducer(initialState, builder =>
	builder
		.addCase(setTotalEtherValueLocked, (state, action) => {
			state.totalEtherValueLocked = action.payload;
		})
		.addCase(setPoolsTokenEtherPrice, (state, action) => {
			state.poolsTokenEtherPrice = action.payload;
		})
		.addCase(setLoading, (state, action) => {
			state.loading = action.payload;
		})
)