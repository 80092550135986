import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { banners } from "../../../constants";
import "./banners.scss";


const SingleBanner = styled.div`
	background-color: ${({ theme }) => theme.primary};
	background-image: ${({ backgroundImage }) => backgroundImage};
	border-radius: 18px;
	position: relative;
	height: 220px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-end;
	
	& > img {
		position: absolute;
		bottom: 84px;
		right: 20px;
		z-index: 1;
		
	}
`

const Content = styled.div`
	position: relative;
	margin-bottom: 43px;
	padding-left: 33px;
	padding-bottom: 10px;
	font-size: 30px;
	font-weight: 700;
	text-transform: uppercase;
	color: ${({ theme }) => theme.white};
	z-index: 10;
	white-space: pre-wrap;
	
	&::before {
		content: "";
		width: 152px;
		height: 2px;
		position: absolute;
		top: 100%;
		left: 0;
		background-image: linear-gradient(270deg, #FFFFFF 19.26%, rgba(255, 255, 255, 0) 102.55%);
	}
	
`

const Banners = (props) => {
	const settings = {
		className: "slider w-100",
		dots: true,
		arrows: false,
		infinite: false,
		centerMode: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		centerPadding: "30px",
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
		],
		dotsClass: "banner__dots slick-dots",
	};

	return (
		<section className="row banners section">
			<div className="banners__container">
				<div className="banners__inner">
					<Slider {...settings}>
						{banners.map((banner, i) => {
							return (
								<div className={"w-100"} key={`banner-${i}`}>
									<a
										href={banner.url}
										target={"_blank"}
										rel={"noopener noreferrer"}
										className={"banner__link"}
									>
										<SingleBanner backgroundImage={banner?.backgroundImage}>
											<Content>
												{banner?.content}
											</Content>
											<img
												src={banner.image.default}
												alt={`banner ${i}`}
												className={"banner__image"}
											/>
										</SingleBanner>
									</a>
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
		</section>
	);
};

export default Banners;
