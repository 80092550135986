import styled from "styled-components";


const LogoImage = styled.img`
	display: flex;
	height: 32px;
	margin-right: 12px;
`

const Logo = (props) => {
	return (
		<a href={"https://trident.systems"} className={"header__logo"}>
			<LogoImage src={require('../../assets/images/global/logo.png').default} alt={process.env.REACT_APP_NAME}/>
			<span className={`text-primary header__logo-title ${props.hideOnMobile ? "hidden lg:block" : ""}`}>
				{process.env.REACT_APP_NAME}
			</span>
		</a>
	);
};

export default Logo;
