import SVG from "react-inlinesvg";
import styled from "styled-components";

import Instagram from "../../assets/images/socials/instagram.svg";
import Facebook from "../../assets/images/socials/facebook.svg";
import Linkedin from "../../assets/images/socials/linkedin.svg";
import Twitter from "../../assets/images/socials/twitter.svg";
import "./styles.scss";

const socials = [
	{
		name: "Instagram",
		image: Instagram,
		url: "https://trident.systems",
	},
	{
		name: "Twitter",
		image: Twitter,
		url: "https://trident.systems",
	},
	{
		name: "Facebook",
		image: Facebook,
		url: "https://trident.systems",
	},
	{
		name: "LinkedIn",
		image: Linkedin,
		url: "https://trident.systems",
	},
];

const StyledLink = styled.a`
	color: ${({ theme }) => theme.white};
`;

const Socials = (props) => {
	return (
		<div className="flex items-center justify-between">
			<ul className={"socials"}>
				{socials.map((social, i) => {
					return (
						<li className={"socials__item"} key={social.name + i}>
							<StyledLink
								href={social.url}
								target={"_blank"}
								rel={"noopener noreferrer"}
								className={"socials__link"}
							>
								<SVG src={social.image} />
							</StyledLink>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Socials;
