import { createAction } from "@reduxjs/toolkit";

import { ApyFinance } from "../../graphql";

const api = new ApyFinance();

export const setTotalEtherValueLocked = createAction('apy/setTotalEtherValue');
export const setPoolsTokenEtherPrice = createAction('apy/setPoolsTokenEtherPrice');
export const setLoading = createAction('apy/loading');

export const fetchPoolsValue = (tokenEthPrices) => {
	return async dispatch => {
		dispatch(setLoading(true));
		const res = await api.fetchPoolsValueLocked(tokenEthPrices);
		dispatch(setTotalEtherValueLocked(res));
		dispatch(setLoading(false));
	}
}
