import styled, { ThemeContext } from "styled-components";
import { useContext } from "react";
import { Loader } from "react-feather";

import "./style.scss";

const Wrapper = styled.div`
	width: ${({ width }) => `${width || 55}px`};
	height: ${({ height }) => `${height || 55}px`};
`;

const Loading = (props) => {
	let color = "primary";
	let customColor = false;
	const theme = useContext(ThemeContext);
	const classNames = ["loading-spine"];

	if (props.active || props.color === "success") {
		classNames.push("active");
	} else if (props.failed || props.color === "danger") {
		classNames.push("failed");
	}

	if (["primary", "secondary", "danger", "warning", "success", "tertiary"].includes(props.color)) {
		color = props.color;
	} else {
		customColor = props.color;
	}

	return (
		<Wrapper color={props.color} width={props.width} height={props.height} className={classNames.join(" ")}>
			<div>
				<Loader size={props.width} color={customColor || theme[color]}/>
			</div>
		</Wrapper>
	);
};

export default Loading;
