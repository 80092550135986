import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import {MainnetAPYPools, KovanAPYPools} from "../constants/apy";
import BigNumber from "bignumber.js";

export const apyFinanceClient = new ApolloClient({
	uri: process.env.REACT_APP_APY_FINANCE_ENV === 'testnet' ? "https://api.thegraph.com/subgraphs/name/apy-finance/apy-finance-kovan" : "https://api.thegraph.com/subgraphs/name/apy-finance/apy-finance",
	cache: new InMemoryCache(),
});

export class Api {
	constructor() {
		this.client = apyFinanceClient;

		this.pools = process.env.REACT_APP_APY_FINANCE_ENV === 'testnet' ? KovanAPYPools : MainnetAPYPools
		this.fetchQuery = gql`
			query totalEthValueLocked($poolAddress: String!, $fromTimestamp: BigInt!, $orderBy: String!, $orderDirection: String!) {
				totalEthValueLockeds(orderBy: $orderBy, orderDirection: $orderDirection, where: {timestamp_gt: $fromTimestamp, poolAddress: $poolAddress}) {
					id
					timestamp
					poolAddress
					totalEthValueLocked
					__typename
				}
			}
		`
	}

	fetchPoolTotalValueLocked({ poolAddress, fromTimestamp = 0, orderBy = "timestamp", orderDirection = "asc" }) {
		return this.client
			.query({
				variables: {
					poolAddress,
					fromTimestamp,
					orderDirection,
					orderBy
				},
				query: this.fetchQuery
			})
	}

	promisify = (callback, price) => {
		return new Promise(async (resolve) => {
			callback()
				.then((response) => {
					resolve(response?.data?.totalEthValueLockeds?.map(item => [
						item?.timestamp,
						new BigNumber(item?.totalEthValueLocked).dividedBy(new BigNumber(price)).toString()
					]))
				})
				.catch((error) => {
					resolve([])
				})
		});
	};

	async fetchPoolsValueLocked(data) {
		let promises = [];
		let keys = [];

		for(let i in data[0]) {
			let pool = data[0][i][1];
			let price = data[1][i];
			let token = data[0][i][0];

			keys.push(token);
			promises.push(this.promisify(() =>
				this.fetchPoolTotalValueLocked({poolAddress: pool})
			, price))
		}

		let res = await Promise.all(promises);

		let result = {};

		for(let i in keys) {
			let pool = res[i];
			result[keys[i]] = {}
			result[keys[i]].totalEtherValueLocked = pool;

		}

		return result;
	}
}
