import { Modal } from 'antd';


const ExportedModal = ({onHide, show, children, ...props}) => {
    return (
        <Modal
            onCancel={onHide}
            visible={show}
            footer={null}
            {...props}
        >
            {children}
        </Modal>
    )
}

export default ExportedModal;